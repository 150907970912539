export default class HeaderView {
    constructor() {
        this.headerElement = document.querySelector('[data-id="header"]');
        this.headerOpen = 'header--open';
    }

    displayHeader() {
        this.headerElement.classList.toggle(this.headerOpen);
    }
}

// Import Views
import HeaderView from './HeaderView.js';

// headerController
const headerController = {
    headerView: null,

    init() {
        this.headerView = new HeaderView();
        this.callHeaderView();
    },

    callHeaderView() {
        this.headerView.displayHeader();
    },
};

// Launch the headerController
const launchHeaderController = () => {
    headerController.init();
};

export default launchHeaderController;

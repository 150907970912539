// Import functions
import launchHeaderController from '../header/headerController.js';

// Import Views
import ToggleView from './ToggleView.js';

// toggleController
const toggleController = {
    toggleView: null,

    init() {
        this.toggleView = new ToggleView();
        this.addEvent();
    },

    addEvent() {
        const toggleElement = document.querySelector('[data-id="toggle"]');
        toggleElement.addEventListener('click', () => {
            launchHeaderController();
            this.callToggleView();
        });
    },

    callToggleView() {
        this.toggleView.displayToggleIcons();
    },
};

// Launch the toggleController
const launchToggleController = () => {
    toggleController.init();
};

launchToggleController();

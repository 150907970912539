export default class ToggleView {
    constructor() {
        this.toggleIconBars = document.querySelector('[data-id="toggleIconBars"]');
        this.toggleIconCross = document.querySelector('[data-id="toggleIconCross"]');
    }

    displayToggleIcons() {
        this.toggleIconCross.classList.toggle('toggle__icon--close');
        this.toggleIconBars.classList.toggle('toggle__icon--open');
    }
}
